export class MyWorker {
  constructor(name, uri, window, callback) {
    if (!window.Worker) {
      throw "Your browser does not support workers";
    }

    const code = uri.toString();
    const blob = new Blob(['(' + code + ')()']);

    this.workerInstance = new Worker(uri);

    this.promise = new Promise(function (resolve, reject) { });
    this.workerInstance.onmessage = function (event) {
      if (callback instanceof Function) {
        callback(event.data, this);
      }
    }
  }

  postMessage(data) {
    this.workerInstance.postMessage(data);
  }

  terminate() {
    if (this.workerInstance) {
      this.workerInstance.terminate();
    }
  }
}
