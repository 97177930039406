import React from 'react';

const TextWithEllipsis = ({ text, limit = 25 }) => {
  const maxLength = limit;
  const isTruncated = text.length > maxLength;
  const displayText = isTruncated ? text.slice(0, maxLength) + "..." : text;

  return (
    <div className="relative">
    <span
      className={`relative ${isTruncated ? 'group hover:cursor-pointer' : ''}`}
    >
      {displayText}
      {isTruncated && (
        <div
          className="absolute left-0 top-full mt-1 w-[200px] p-2 bg-gray-700 text-white text-[9px] rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-50 leading-normal pointer-events-none"
        >
          {text}
        </div>
      )}
    </span>
  </div>
  );
};

export default TextWithEllipsis;
