import React, { useState, useEffect } from 'react';
import useProductOptions from '../../hooks/useProductOptions';
import CustomizableNote from '../CustomizableNote';
import CartView from '../CartView';

const ProductInfo = ({ productData, storeDetails, onAddToCart, onImageChange,cartItems }) => {
  const {
    selectedOptions,
    allOptions,
    availableOptions,
    allOptionsSelected,
    handleOptionChange,
    selectedVariant,
    activeImage,
    isProductUnavailable,
    getDiscountPercentage,
    isOptionAvailable
  } = useProductOptions(productData, storeDetails);

  const [customizableNote, setCustomizableNote] = useState('');
  const priceInfo = getDiscountPercentage();
  const [isTemporarilyOutOfStock, setIsTemporarilyOutOfStock] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeImage) {
      onImageChange(activeImage);
    }
  }, [activeImage]);

  useEffect(() => {
    const allOptionsHaveValue = Object.values(selectedOptions).every(option => option !== null);
    setIsTemporarilyOutOfStock(isProductUnavailable());
  }, [selectedOptions, isProductUnavailable]);

  const handleCustomizableNoteChange = (note) => {
    setCustomizableNote(note);
  };

 
  const handleAddToCart = () => {
    onAddToCart(selectedVariant, customizableNote);
    setIsLoading(true);
    setIsCartOpen(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const checkAlreadyInCart = () => {
    let alreadyInCart = false;

    cartItems?.map((cartItem) => {
      cartItem?.cart_items?.map((cart_item) => {
        if(cart_item?.product_variants?.id == selectedVariant?.id)
        {
          alreadyInCart = true
        }
       
      })

    })
    return alreadyInCart;
  }

 
  return (
    <div className="lg:w-1/2">
      <h1 className="text-3xl font-bold mb-2">{productData.productDetails.title}</h1>
      <div className="flex items-center mb-2">
        <img src={productData.productDetails.user.brand_details.store_logo} alt="Brand logo" className="w-6 h-6 mr-2" />
        <span>{productData.productDetails.user.brand_details.store_name}</span>
      </div>
      <div className="mb-4">
        {priceInfo.hasDiscount ? (
          <div>
            <span className="text-gray-500 line-through">${priceInfo.originalPrice}</span>
            <span className="ml-2 text-lg font-bold text-green-700">${priceInfo.discountedPrice}</span>
          </div>
        ) : (
          <span className="text-lg font-bold">${priceInfo.originalPrice}</span>
        )}
      </div>
      {(productData?.productDetails?.product_options[0]?.value !== "Default Title" && productData?.productDetails?.product_options[0]?.name !== "Title" && allOptions['option1'].length > 0) && (
        <>
          {['option1', 'option2', 'option3'].map((optionKey, index) => {
            const optionName = productData.productDetails.product_options[index]?.name;
            if (!optionName || allOptions[optionKey].length === 0) return null;
            return (
              <div key={optionKey} className="mb-4">
                <p className="mb-2 font-semibold">{optionName}:</p>
                <div className="flex flex-wrap gap-2">
                  {allOptions[optionKey].map((option) => {

                    const isAvailable = isOptionAvailable(optionKey, option);
                    return (
                      <button
                        key={option}
                        className={`px-4 py-2 border rounded ${selectedOptions[optionKey] === option
                          ? 'border-prm text-prm'
                          : 'border-gray-300 text-gray-700'
                          } ${isAvailable ? '' : 'opacity-50 cursor-not-allowed'}`}
                        onClick={() => isAvailable && handleOptionChange(optionKey, option)}
                        disabled={!isAvailable}
                      >
                        <span
                          className={isAvailable ? '' : 'relative'}
                          style={!isAvailable ? {
                            textDecoration: 'line-through',
                            textDecorationColor: 'currentColor',
                            textDecorationThickness: '2px'
                          } : {}}
                        >
                          {option}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}


      {productData.productDetails.is_customizable && (
        <CustomizableNote
          limit={productData.productDetails.customizable_note_limit}
          placeholder={productData.productDetails.customizable_note}
          value={customizableNote}
          onChange={handleCustomizableNoteChange}
        />
      )}
      <button
        className={`w-full py-3 rounded-lg mb-4 text-lg font-semibold ${isTemporarilyOutOfStock
          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
          : 'bg-primary text-white hover:bg-primary transition-colors'
          }`}
        onClick={handleAddToCart}
        disabled={isTemporarilyOutOfStock}
      >
        {
          checkAlreadyInCart() ? 'Go to cart' : 'Add to cart'
        }
        
      </button>

      {isTemporarilyOutOfStock && (
        <p className="text-red-500 mb-4">
          {allOptionsSelected ? 'Selected variant is out of stock' : 'Out of stock'}
        </p>
      )}

      <div className="bg-gray-100 p-3 rounded-lg text-sm">
      This item will ship from {productData.productDetails.user.brand_details.store_name} within {productData.productDetails.shipping_time?.replace('_days', ' ')?.replace('gt', '>')} days.
      </div>

      <CartView isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} loading={isLoading} />
    </div>
  );
};

export default ProductInfo;