import React from 'react';
import CustomerInformation from '../../components/CustomerInfo';

const Checkout = () => {
    return (
        <main className="container mx-auto px-2 py-4">
            <CustomerInformation />
        </main>
    );
};

export default Checkout;
