import React, { useState,useRef,useEffect } from "react";
import { Share,Mail,Copy } from 'lucide-react';
import { toast } from 'react-toastify';

const ShareDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMailClick = () => {
    window.location.href = `mailto:?subject=Check this out&body=Here is the link: ${window.location.origin}`;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.origin);
    console.log("Jello");
    toast.success('Link copied to clipboard!', { autoClose: 3000 });
  };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

  return (
    <div className="relative inline-block text-left"  ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
      >
        <Share />
      </button>

      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg"
        >
          <button
            onClick={handleMailClick}
            className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex gap-1 text-navy50"
          >
            <Mail/> <p>Email</p>
          </button>
          <button
            onClick={handleCopyLink}
            className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex gap-1  text-navy50"
          >
             <Copy/><p>Copy Link</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default ShareDropdown;
