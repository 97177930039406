import React from 'react';
import PaymentDetails from '../../components/PaymentDetails';

const Payment = () => {
    return (
        <main className="container mx-auto px-2 py-4">
            <PaymentDetails />
        </main>
    );
};

export default Payment;
